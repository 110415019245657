<template>
  <section class="FacturacionTickets empresas__inner container mt24 mb24">
    <ButtonBackHome />
    <header class="empresas__header">
      <h2 class="empresas_home__heading heading">Solicita tu factura</h2>
      <div class="empresas_home__text">El Folio y WebID lo podrás encontrar en tu ticket después del domicilio y antes de los servicios y artículos proveídos.<br><span class="isAlert">Solo se podrán solicitar facturas dentro del periodo del mes en que se emitió tu ticket.</span><br><span class="isAlert">En caso de ser pago de Colegiaturas, Cursos o conceptos relacionados solo se podrán solicitar facturas el mismo día del ticket.</span></div>
    </header>
    <v-card
      v-if="!regimenFiscalDialog"
      flat
      class=""
      :disabled="cargando"
      :loading="cargando"
    >
      <v-row>
        <v-col
          cols="12"
          sm="6"
        >
          <v-text-field
            label="Folio"
            filled
            v-mask="'########'"
            v-model="ticket.folio"
            :error-messages="errors.folio"
            @keyup.enter="_validateTicket"
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
        >
          <v-text-field
            label="WebID"
            filled
            v-mask="'NNNNNN'"
            v-model="ticket.webid"
            :error-messages="errors.webid"
            @keyup.enter="_validateTicket"
          />
        </v-col>
      </v-row>
      <v-card-actions class="card_no_padding">
        <v-spacer />
        <v-btn
          @click="_validateTicket"
          outlined
        >
          <v-icon v-text="'add'" />
          Agregar
        </v-btn>
      </v-card-actions>
      <br>
      <v-data-table
        class="upper"
        v-if="tickets.length>=1"
        :headers="headers"
        :items="tickets"
        :items-per-page="5"
      >
        <template v-slot:item.importe="{ item }">
          {{$nF(item.importe)}}
        </template>
        <template v-slot:item.fecha="{ item }">
          {{ _doDiaMesHoras(item.fecha).toUpperCase() }}
        </template>
        <template v-slot:item.acciones="{ item }">
          <v-btn
            @click="_deleteTicket(item.cargo)"
            dense
            icon
          >
            <v-icon v-text="'delete'" />
          </v-btn>
        </template>
      </v-data-table>
      <v-card-actions class="card_no_padding">
        <v-spacer />
        <v-btn
          text
          v-text="'Cancelar'"
          @click="$router.push('/empresas')"
        />
        <v-btn
          v-if="tickets.length>=1"
          class="primary"
          depressed
          v-text="'Continuar'"
          @click="showDialogUsoCFDI = true"
        />
      </v-card-actions>
    </v-card>
    <v-dialog
      v-model="showDialogUsoCFDI"
      width="400"
    >
      <v-card
        :loading="cargando"
        :disabled="cargando"
      >
        <v-card-title v-text="'¿Cuál sera el uso de CFDI?'" />
        <v-card-text>
          <v-autocomplete
            v-model="cfdi"
            append-icon="search"
            :items="tiposUsosCFDI"
            label="Seleccione el uso del CFDI"
            filled
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            v-text="'Cancelar'"
            @click="showDialogUsoCFDI=false"
          />
          <v-btn
            :disabled="!cfdi"
            color="primary"
            depressed
            v-text="'Solicitar factura'"
            @click="_doSolicitarFacturas"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="regimenFiscalDialog"
      persistent
      width="800"
    >
      <v-card
        :loading="cargando"
        :disabled="cargando"
      >
        <v-card-title class="nowrap">Por favor verifica y actualiza tu información para poder solicitar tu factura en versión CFDI 4.0</v-card-title>
        <v-card-text>
          <v-row>
            <v-col
              sm="12"
              md="6"
            >
              <v-text-field
                label="Razón Social*"
                v-model="empresa.razon_social_don"
                filled
                :hint="empresa.tipo_don=='moral'?'No debe incluir el régimen capital (IAP, SC, SA de CV...)':''"
                :persistent-hint="empresa.tipo_don=='moral'"
              />
            </v-col>
            <v-col
              sm="12"
              md="6"
            >
              <v-select
                label="Régimen fiscal*"
                v-model="empresa.regimen_fiscal_don"
                filled
                :items="regimenes[empresa.tipo_don]"
              />
            </v-col>
            <v-col
              sm="12"
              md="6"
            >
              <v-text-field
                label="CP*"
                v-model="empresa.cp_don"
                filled
              />
            </v-col>
            <v-col
              sm="12"
              md="6"
            >
              <v-file-input
                filled
                label="Constancia de situación fiscal*"
                prepend-icon=""
                prepend-inner-icon="attach_file"
                accept="image/jpeg,image/png,application/pdf,image/x-eps"
                v-model="file"
              />
            </v-col>
          </v-row>

          Es importante la información que has capturado sea correcta para poder generar tu factura.
          <a
            style="color:red!important;font-weight: 400!important;"
            href="https://agsc.siat.sat.gob.mx/PTSC/ValidaRFC/index.jsf"
            target="_blank"
          >Puedes validarla aquí</a>a
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="$router.push('/empresas')"
          >Cancelar</v-btn>
          <v-btn
            :disabled="!empresa.razon_social_don || !empresa.regimen_fiscal_don || !empresa.cp_don || !file"
            color="primary"
            @click="_updateEmpresaInfo"
          >Actualizar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </section>
</template>
<script>
import standard from "@/mixins/standard.js";
import forms from "@/mixins/forms.js";
import dates from "@/mixins/dates.js";
export default {
  name: "FacturacionTickets",
  mixins: [standard, forms, dates],
  data() {
    return {
      cargando: false,
      ticket: {
        folio: null,
        webid: null,
      },
      errors: {
        folio: null,
        webid: null,
      },
      tickets: [],
      showDialogPreCancelar: false,
      headers: [
        { text: "Folio", value: "folio" },
        { text: "Fecha", value: "fecha" },
        { text: "Servicios", value: "servicios" },
        { text: "Importe", value: "importe", align: "end" },
        { text: "Metodos de pago", value: "metodos_pago" },
        { text: "", value: "acciones" },
      ],
      showDialogUsoCFDI: false,
      cfdi: null,
      tiposUsosCFDI: [
        "G01 - Adquisición de Mercancías",
        "G03 - Gastos en General",
        "D01 - Honorarios médicos, dentales y gastos hospitalarios",
        "D04 - Donativos",
        "D10 - Pagos por Servicios Educativos (Colegiaturas)",
        "P01 - Por Definir",
      ],
      regimenFiscalDialog: false,
      regimenes: {
        fisica: [
          "605 - Sueldos y Salarios e Ingresos Asimilados a Salarios",
          "606 - Arrendamiento",
          "607 - Régimen de Enajenación o Adquisición de Bienes",
          "608 - Demás ingresos",
          "610 - Residentes en el Extranjero sin Establecimiento Permanente en México	Sí	Sí",
          "611 - Ingresos por Dividendos (socios y accionistas)",
          "612 - Personas Físicas con Actividades Empresariales y Profesionales",
          "614 - Ingresos por intereses",
          "615 - Régimen de los ingresos por obtención de premios",
          "616 - Sin obligaciones fiscales",
          "621 - Incorporación Fiscal",
          "625 - Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas",
          "626 - Régimen Simplificado de Confianza",
        ],
        moral: [
          "601 - General de Ley Personas Morales",
          "603 - Personas Morales con Fines no Lucrativos",
          "610 - Residentes en el Extranjero sin Establecimiento Permanente en México",
          "620 - Sociedades Cooperativas de Producción que optan por diferir sus ingresos",
          "622 - Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras",
          "623 - Opcional para Grupos de Sociedades",
          "624 - Coordinados",
          "626 - Régimen Simplificado de Confianza",
        ],
      },
      file: null,
      empresa: {
        cp_don: null,
        razon_social_don: null,
        regimen_fiscal_don: null,
        tipo_don: "moral",
        constancia_fiscal_don: null,
      },
    };
  },
  methods: {
    _validateTicket() {
      if (!this._validateAll("ticket")) {
        this.$emit("msg", "Llene los campos indicados");
        return;
      }
      if (this._isTicketListed()) {
        this.$emit("msg", "El ticket que desea agregar ya fue agregado.");
        return;
      }
      this._saveAll("facturacion/buscar", this.ticket, "ticket", "_addTicket");
    },
    _findTicketIndex(folio) {
      const ff = parseInt(folio);
      var index = this.tickets.findIndex(function (e) {
        return parseInt(e.cargo) === ff;
      });
      return index;
    },
    _isTicketListed() {
      if (this.tickets.length >= 1) {
        var folio = parseInt(this.ticket.folio);
        var index = this._findTicketIndex(folio);
        return index ? false : true;
      } else {
        return false;
      }
    },
    _addTicket(res) {
      var servicios = res.ticket.servicios;

      const {
        ticket: { cargo, fecha, folio, id, importe, metodos_pago },
      } = res;

      servicios = servicios.length > 59 ? servicios.concat("...") : servicios;
      this.tickets.unshift({
        cargo: cargo,
        fecha: fecha,
        folio: folio,
        id: id,
        importe: importe,
        servicios: servicios,
        metodos_pago: metodos_pago,
      });
      this.$emit("msg", "Ticket agregado");
      this._limpiarCampos("ticket");
      this._limpiarCampos("errors");
    },
    _deleteTicket(folio) {
      let index = this._findTicketIndex(folio);
      if (index >= 0) {
        this.tickets.splice(index, 1);
        this.$emit("msg", "Ticket removido");
      }
    },
    _doSolicitarFacturas() {
      const data = {
        tickets: this.tickets,
        cfdi: this.cfdi,
      };
      this._saveAll("facturacion/solicitar", data, "msg", "_redirectListado");
    },
    _redirectListado(res) {
      this.$router.push("/empresas/facturas");
      this.$emit("msg", res.msg);
    },
    async _donadorHasRegimenFiscal() {
      this.cargando = true;
      try {
        const { body } = await this.$http.get(
          "donadores/regimenfiscal",
          this.$headers
        );
        this.cargando = false;
        if (body && "empresa" in body) {
          this.empresa = Object.assign({}, body.empresa);
          if (
            !body.empresa.regimen_fiscal_don ||
            body.empresa.regimen_fiscal_don == "" ||
            !body.empresa.constancia_fiscal_don
          )
            this.regimenFiscalDialog = true;
        } else {
          this.$router.push("/empresas");
        }
      } catch (e) {
        this.cargando = false;
        this.$router.push("/empresas");
      }
    },
    async _updateEmpresaInfo() {
      this.cargando = true;
      try {
        var data = new FormData();
        data.append("file", this.file);
        data.append("empresa", JSON.stringify(this.empresa));
        this._saveAll(
          "donadores/regimenfiscal",
          data,
          "msg",
          "_handleUpdateEmpresa"
        );
      } catch (e) {
        this.$emit(
          "msg",
          "Sucedió un error al actualizar tu información. Inténtalo de nuevo más tarde."
        );
      }
    },

    _handleUpdateEmpresa(res) {
      const genericError =
        "Sucedió un error al actualizar tu información. Inténtalo de nuevo más tarde.";
      try {
        if ("msg" in res) {
          this.regimenFiscalDialog = false;
          this.$emit("msg", "Gracias por actualiar tu información");
        } else {
          this.$emit("msg", genericError);
        }
      } catch (e) {
        this.$emit("msg", genericError);
      }
    },
  },
  mounted() {
    this._donadorHasRegimenFiscal();
  },
};
</script>